import React from "react";
const Cta=()=>{
    return (
      <>
        <section className="section cta-wrapper">
          <div className="container-xxl">
            <div className="row">
            <div className="col-md-8">
              <div className="row ">
              <div className="col-md-6 d-flex ">
                <img src="../images/cta-girl.svg" className="img-fluid" alt="CTA"></img>
              </div>
              <div className="col-md-6  d-flex align-items-center justify-content-center ">
                <div className="">
              <h3 className="secondary-color d-block w-100 text-bold pt-4">Contact us </h3>
              <h4 className="d-block w-100 text-bold primary-color ">Let’s talk about
Love to hear from you.</h4>
              </div></div>
              </div>
              <div className="row">
                <div className="col-md-6">
                <div className="d-flex align-items-start mb-3 mt-4">
                    <img src="../images/icon/map-marker.svg" className="me-2 " alt="map"></img>
                     <div  className="text-start pl-2 ">
                    <h5 className="text-bold">Our Location</h5>
                      #356, Aggarwal Metro Heights, Netaji Subhash Place, Delhi
                      110034.
                      </div>
                  </div>
                </div>
                <div className="col-md-6">
                <div className="d-flex align-items-start mb-3 mt-4">
                    <img src="../images/icon/mail.svg" className="me-2 " alt="mail"></img>
                     <div  className="text-start ">
                    <h5 className="text-bold ">How Can We Help?</h5>
                    <ul className="list-unstyled">
                <li className="mb-2">info@insurekraft.com</li>
                <li>+91-98106 02899</li>
                </ul>
                  
                      </div>
                  </div>
                </div>
       
              </div>

            </div>
            <div className="col-md-4">
            <img src="../images/email.svg" className="img-fluid" alt="email"></img>
            </div>


           
            </div>
          </div>
          
      </section>
        
      </>

      );
    }
    
export default Cta;

