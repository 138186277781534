import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <section>
        <img src="../images/wave-footer.svg" className="w-100"  alt="footer"/>
      </section>
      <footer className="page-footer font-small blue pt-4 pb-4">
        <div className="container-xxl ">
          <div className="row">
            <div className="col-md-3 mt-md-0 mt-3">
              <div className="mb-3">
                {" "}
                <img src="../images/logo.svg" alt="logo" />
              </div>
              <ul className="list-unstyled">
                <li>
                  <span className="d-flex align-items-start mb-3 ">
                    <img src="../images/icon/map-marker.svg" className="me-2" alt="map"></img>
                    <span className="text-start pl-2">
                      #356, Aggarwal Metro Heights, Netaji Subhash Place, Delhi
                      110034.
                    </span>
                  </span>
                </li>
                <li className="mb-3">
                  <span className="d-flex">
                    <img src="../images/icon/phone.svg"  className="me-2" alt="phone"></img>
                    <span className="text-start pl-2">+91-98106 -02899</span>
                  </span>
                </li>
                <li className="mb-3">
                  <span className="d-flex">
                    <img src="../images/icon/mail.svg" className="me-2" alt="mail"></img>
                    <span className="text-start">info@insurekraft.com</span>
                  </span>
                </li>
              </ul>
            </div>
            <hr className="clearfix w-100 d-md-none pb-0" />
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-bold primary-color">General Insurance</h5>
              <ul className="link">
                <li>
                  <Link to="/"> Car Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Bike Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Motor Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Third Party Car Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/">Third Party Bike Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Travel Insurance</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-bold primary-color">Life Insurance</h5>
              <ul className="link">
                <li>
                  <Link to="/"> Term Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Term Insurance Calculator</Link>{" "}
                </li>
                <li>
                  <Link to="/"> 1 Crore Term Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Term Return of Premium</Link>{" "}
                </li>
                <li>
                  <Link to="/">Term Insurance for Housewife</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Spouse Term Plan</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-bold primary-color">Other Insurance</h5>
              <ul className="link">
                <li>
                  <Link to="/">Group Health Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/">Other General Insurance Products</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Cancer Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Home Insurance</Link>{" "}
                </li>
              
              </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-bold primary-color">Investment</h5>
              <ul className="link">
                <li>
                  <Link to="/">Investment Plans</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Child Plans</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Pension Plans</Link>{" "}
                </li>
                <li>
                  <Link to="/"> ULIPs</Link>{" "}
                </li>
                <li>
                  <Link to="/">Money Back Policy</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Endowment Policy</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Guaranteed Return Plans</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Tax Saving Plans</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-bold primary-color">Health Insurance</h5>
              <ul className="link">
                <li>
                  <Link to="/"> Health Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/">1 Cr Health Cover</Link>{" "}
                </li>
                <li>
                  <Link to="/">Family Health Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Senior Citizen Health Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/">Coronavirus Insurance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Corona Kavach Policy</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Corona Rakshak Policy</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Arogya Sanjeevani Policy</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-bold primary-color">Add-ons</h5>
              <ul className="link">
                <li>
                  <Link to="/"> Zero Depreciation</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Invoice Cover</Link>{" "}
                </li>
                <li>
                  <Link to="/"> 24x7 Roadside Assistance</Link>{" "}
                </li>
                <li>
                  <Link to="/"> NCB Protector</Link>{" "}
                </li>
               
              </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-bold primary-color">Other Link</h5>
              <ul className="link">
                <li>
                  <Link to="/">About Us</Link>{" "}
                </li>
                <li>
                  <Link to="/">Our Team</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Disclosure</Link>{" "}
                </li>
                <li>
                  <Link to="/"> Privacy Policy</Link>{" "}
                </li>
                <li>
                  <Link to="/">Refund & Cancellation</Link>{" "}
                </li>
                <li>
                  <Link to="/">Terms & Condition</Link>{" "}
                </li>
                <li>
                  <Link to="/">Contact Us</Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="container-xxl">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center fs-6 pt-4 pb-4">
              Monetic Insurance Broker Private Limited , IRDAI Insurance Broker
              License No - 823 , License Code - IRDAI/DB909/2021 ,Valid Till -
              30/05/2025<br></br>
              CIN Number - U66000DL2021PTC382340, Insurance is the subject
              matter of solicitation
            </div>
            <ul class="list-inline text-center">
              <li class="list-inline-item">
                <img src="../images/godaddy.svg" alt="irda"></img>
              </li>
              <li class="list-inline-item">
                <img src="../images/irda.svg" alt="irda"></img>
              </li>
            </ul>
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="footer-copyright">
              Copyright 2023. All rights reserved.Monetic Insurance Broker
              PrivateLimited
   
            </div>
          </div>
          <div className="col-md-4 ">
          <ul class="list-inline float-md-end">
                <li class="list-inline-item"><Link to="/"> <img src="../images/icon/fb.svg" alt="FB"></img></Link></li>
                <li class="list-inline-item"><Link to="/"> <img src="../images/icon/TWITTER.svg" alt="FB"></img></Link></li>
                <li class="list-inline-item"><Link to="/"> <img src="../images/icon/linkedin.svg" alt="FB"></img></Link></li>
                <li class="list-inline-item"><Link to="/"> <img src="../images/icon/instagram.svg" alt="FB"></img></Link></li>
                <li class="list-inline-item"><Link to="/"> <img src="../images/icon/whatapp.svg" alt="FB"></img></Link></li>
                <li class="list-inline-item"><Link to="/"> <img src="../images/icon/youtube.svg" alt="FB"></img></Link></li>
              </ul>
          </div>
        </div>
        
      </div>
    </>
  );
};
export default Footer;
