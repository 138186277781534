import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const Latestblogs = () => {
  return (
    <>
      <section className="section ">
        <h3 className="text-center mainheading mb-5">
          Latest<span className="secondary-color"> Blog Posts </span>
        </h3>
        <div className="container-xxl">
          <div className="row">
            <div className="col-md-4">
              <Card style={{}} className="shadow">
                <Card.Img
                  variant="top"
                  src="../images/blog.jpg"
                  className="img-fluid" alt="blog"
                />
                <Card.Body>
                  <ul class="list-inline ">
                    <li class="list-inline-item">
                      {" "}
                      <span class="badge rounded-pill bg-warning ">
                        Article
                      </span>
                    </li>
                    <li class="list-inline-item float-end primary-color">
                      16 March. 2023
                    </li>
                  </ul>
                  <Card.Title className="text-bold">
                    Is Suicide Covered Under Life Insurance Policy
                  </Card.Title>
                  <Card.Text>
                    Life insurances are your safety protocols. They are to
                    support the family after their family member’s demise
                    financially.
                  </Card.Text>
                  <Button variant="outline">
                    Learn more <img src="../images/icon/right-arrow.svg" alt="arrow"></img>
                  </Button>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{}} className="shadow">
                <Card.Img
                  variant="top"
                  src="../images/blog.jpg"
                  className="img-fluid" alt="blog"
                />
                <Card.Body>
                  <ul class="list-inline ">
                    <li class="list-inline-item">
                      {" "}
                      <span class="badge rounded-pill bg-warning ">
                        Article
                      </span>
                    </li>
                    <li class="list-inline-item float-end primary-color">
                      16 March. 2023
                    </li>
                  </ul>
                  <Card.Title className="text-bold">
                    Is Suicide Covered Under Life Insurance Policy
                  </Card.Title>
                  <Card.Text>
                    Life insurances are your safety protocols. They are to
                    support the family after their family member’s demise
                    financially.
                  </Card.Text>
                  <Button variant="outline">
                    Learn more <img src="../images/icon/right-arrow.svg" alt="arrow"></img>
                  </Button>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{}} className="shadow">
                <Card.Img
                  variant="top"
                  src="../images/blog.jpg"
                  className="img-fluid" alt="blog"
                />
                <Card.Body>
                  <ul class="list-inline ">
                    <li class="list-inline-item">
                      {" "}
                      <span class="badge rounded-pill bg-warning ">
                        Article
                      </span>
                    </li>
                    <li class="list-inline-item float-end primary-color">
                      16 March. 2023
                    </li>
                  </ul>
                  <Card.Title className="text-bold">
                    Is Suicide Covered Under Life Insurance Policy
                  </Card.Title>
                  <Card.Text>
                    Life insurances are your safety protocols. They are to
                    support the family after their family member’s demise
                    financially.
                  </Card.Text>
                  <Button variant="outline">
                    Learn more <img src="../images/icon/right-arrow.svg" alt="arrow"></img>
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Latestblogs;
