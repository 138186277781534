import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Header from "../Layout/Header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
//import Table from "react-bootstrap/Table";
import Footer from "../Layout/Footer";
import Clients from "../Layout/Clients";
import Carfaq from "./Car-faq";
import Cta from "../Layout/Cta";
import Latestblogs from "../Layout/Latestblogs";
import Testimonial from "../Layout/Testimonial";
const VehicleInsurance = () => {
  return (
    <>
      <Header />
      <section className="carhero-wrapper">
        <div className="container-xxl">
          <div className="row g-0">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <img
                src="../images/motor-cycle.svg"
                alt="motor-cycle"
                class="img-fluid"
              />
            </div>
            <div className="col-md-4">
              <div className="bg-white mt80 p-5 radius15">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Vehicle Registration Number</Form.Label>
                    <Form.Control size="lg" type="text" placeholder="AS-23-S-5840"  />
                  </Form.Group>
                  <Link to="/vehicle-journey">
                  <Button variant="primary w-100 radius30" type="submit">
                    Compare Quotes <i className="w-circle"><img src="../images/icon/right-arrow.svg" alt="ok"></img></i>
                  </Button>
                  </Link>
                  <div className="line">
                  <span className="or">OR</span></div>
                  <Button variant="secondary radius30 w-100">
                    Without car number <i className="w-circle"><img src="../images/icon/right-arrow.svg" alt="ok"></img></i>
                  </Button>
                  <div  className="text-center mt-2">Buying a new car  ? <Link to=".." className="text-center">Click here</Link></div>
                </Form>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <img
                src="../images/car.svg"
                alt="motor-cycle"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <img src="../images/bg-wave.svg" className="w-100" alt="wave" />
      </section>
      <section className="section">
        <div className="container-xxl">
          <div className="row">
            <div className="col-md-6">
              <h1 className="mainheading">
                What is
                <span className="d-block secondary-color">Car Insurance?</span>
              </h1>
              <p className="text-justify">
                Car insurance is a contract between a car owner and a general
                insurance company wherein the latter promises to protect the car
                owner from financial losses which may occur due to an
                unfortunate event involving his/her car. Depending on the scope
                of coverage, there are 3 types of car insurance policies -
                Third-Party Car Insurance, Standalone Own-Damage (OD) Car
                Insurance, and Comprehensive Car Insurance. To continue to avail
                the benefits of these policies, the car insurance renewal should
                be processed on time by policyholders.
              </p>
            </div>
            <div className="col-md-6">
              <img src="../images/car.webp" className="img-fluid" alt="car" />
            </div>
          </div>
          <div className="row mt-4">
          <div className="col-md-4">
              <Card className=" text-center shadow ">
                <Card.Body>
                  <Card.Title>
                    <div className="iconbox shadow">
                    <img src="../images/icon/car.svg"  alt="car"/>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mainheading2 mb-3">
                    Comprehensive  <span className="secondary-color">Car Insurance</span>
                  </Card.Subtitle>
                  <Card.Text>
                    A comprehensive car insurance policy is the most extensive
                    car insurance cover under which an insurance company is
                    liable to pay for financial liabilities incurred towards a
                    third-party as well as for the own-damages sustained by the
                    insured’s car. In addition to road accidents
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="altbg text-center shadow ">
                <Card.Body>
                  <Card.Title>
                    <div className="iconbox shadow">
                    <img src="../images/icon/car.svg"  alt="car"/>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mainheading2 mb-3">
                    Comprehensive  <span className="secondary-color">Car Insurance</span>
                  </Card.Subtitle>
                  <Card.Text>
                    A comprehensive car insurance policy is the most extensive
                    car insurance cover under which an insurance company is
                    liable to pay for financial liabilities incurred towards a
                    third-party as well as for the own-damages sustained by the
                    insured’s car. In addition to road accidents
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="text-center shadow ">
                <Card.Body>
                  <Card.Title>
                    <div className="iconbox shadow">
                    <img src="../images/icon/car.svg"  alt="car"/>
                    </div>
                  </Card.Title>
                  <Card.Subtitle className="mainheading2 mb-3">
                    Comprehensive  <span className="secondary-color">Car Insurance</span>
                  </Card.Subtitle>
                  <Card.Text>
                    A comprehensive car insurance policy is the most extensive
                    car insurance cover under which an insurance company is
                    liable to pay for financial liabilities incurred towards a
                    third-party as well as for the own-damages sustained by the
                    insured’s car. In addition to road accidents
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className="benefit-wrapper section">
        <div className="container-xxl ">
          <div className="row align-items-center">
            <div className="col-md-4   ">
              <img src="../images/car-withman.webp" className="img-fluid" alt="car" />
            </div>
            <div className="col-md-8">
              <div className="ps-5 pt-5 pb-5 pr-0 bg-white  radius30">
                <h2 className="mainheading">
                Benefits of Buying
                <span className="d-block secondary-color">Car Insurance?</span>
              </h2>

                <strong>Helps You Stay Legally Compliant</strong>
                <p className="text-justify">
                  A car insurance helps you stay legally compliant as according
                  to Indian Motor Tariff, every car owner is mandatorily
                  required to have at least a third-party car insurance policy.
                  The absence of which is punishable with a fine of Rs. 2,000
                  and/or imprisonment of up to 3 months.
                </p>
                <strong>Helps You Stay Legally Compliant</strong>
                <p className="text-justify">

                  A car insurance helps you stay legally compliant as according
                  to Indian Motor Tariff, every car owner is mandatorily
                  required to have at least a third-party car insurance policy.
                  The absence of which is punishable with a fine of Rs. 2,000
                  and/or imprisonment of up to 3 months.
                </p>
                <strong>Helps You Stay Legally Compliant</strong>
                <p className="text-justify">
                  A car insurance helps you stay legally compliant as according
                  to Indian Motor Tariff, every car owner is mandatorily
                  required to have at least a third-party car insurance policy.
                  The absence of which is punishable with a fine of Rs. 2,000
                  and/or imprisonment of up to 3 months.
                </p>
                <strong>Helps You Stay Legally Compliant</strong>
                <p className="text-justify">

                  A car insurance helps you stay legally compliant as according
                  to Indian Motor Tariff, every car owner is mandatorily
                  required to have at least a third-party car insurance policy.
                  The absence of which is punishable with a fine of Rs. 2,000
                  and/or imprisonment of up to 3 months.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="altbg section">
        <div className="container-xxl">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center mainheading mb-3">
                Comparison of Different
                <span className="d-block secondary-color">Car Insurance Plans</span>
              </h3>
              <div className="table-responsive">
  <table className="table  bg-white  table-striped rounded-4 shadow-sm">
  <thead>
                  <tr>
                    <th>Coverage</th>
                    <th className="text-center">Third-Party Cover </th>
                    <th className="text-center"> Standalone OD Cove</th>
                    <th className="text-center"> Comprehensive Cover</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Third-Party Bodily Injuries</td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/cancel.svg" alt="cancel"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                  </tr>
                  <tr>
                    <td>Third-Party Property Damage</td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/cancel.svg" alt="cancel"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                  </tr>
                  <tr>
                    <td>Car’s Own-Damages </td>
                    <td className="text-center">
                      <img src="../images/icon/cancel.svg" alt="cancel"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                  </tr>
                  <tr>
                    <td>Car’s Own-Damages </td>
                    <td className="text-center">
                      <img src="../images/icon/cancel.svg" alt="cancel"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                  </tr>
                  <tr>
                    <td>Car’s Own-Damages </td>
                    <td className="text-center">
                      <img src="../images/icon/cancel.svg" alt="ok"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                  </tr>
                  <tr>
                    <td>Car’s Own-Damages </td>
                    <td className="text-center">
                      <img src="../images/icon/cancel.svg" alt="cancel"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                    <td className="text-center">
                      <img src="../images/icon/ok.svg" alt="ok"></img>
                    </td>
                  </tr>
                </tbody>
  </table>
</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-xxl">
            <div className="row">
                <div className="col-md-12">
                <Clients />

                </div>
            </div>
        </div>
      </section>
      <Carfaq />
      <Testimonial/>
      <Cta/>
      <Latestblogs/>
      <Footer />
    </>
  );
};
export default VehicleInsurance;
