import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const KycSuccess = () => {
  return (
    <>
      <Header />
      <section className="altbg section ">
        <div className="container-xxl">
          <div className="col-md-12">
          <div className="text-center">
            <div className="d-block">
                 <svg xmlns="http://www.w3.org/2000/svg" className="text-success" width="75" height="75"
                        fill="currentColor" span="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    </div>
                    <h1 className="text-success text-bold-800 mt-3"  >KYC Verification Successful !</h1>
          </div>
          </div>
        </div>
      </section>
      <section>
        <img src="../images/bg-wave.svg" className="w-100" alt="wave" />
      </section>
      <Footer />
    </>
  );
};
export default KycSuccess;
