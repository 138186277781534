
import "../src/fonts/OpenSans-Regular.ttf";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/App.css';
import "../src/css/Mystyle.css";
import { Route,Switch } from 'react-router-dom';
import Homepage from './Components/Homepage';
import VehicleJourney from './Components/VehicleJourney/VehicleJourney';
import VehicleInsurance from './Components/VehicleInsurance/VehicleInsurance';
import VehicleResult from "./Components/VehicleResult";
import Kyc from "./Components/Kyc/Kyc";
import KycSuccess from "./Components/Kyc/KycSuccess";
import React from "react";
const App=()=> {
  return (
    <>
<Switch>
    <Route exact path="/" component={Homepage} />
     <Route exact path="/vehicle-insurance" component={VehicleInsurance} />
     <Route exact path="/vehicle-journey" component={VehicleJourney} />
     <Route exact path="/vehicle-result" component={VehicleResult} />
     <Route exact path="/kyc" component={Kyc} />
     <Route exact path="/kyc-success" component={KycSuccess} />

</Switch>
    </>
  );
};
export default App;
