import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Stepper, Step } from "react-form-stepper";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import InputGroup from "react-bootstrap/InputGroup";
import Tabs from "react-bootstrap/Tabs";
const VehicleJourney = () => {
  const [goSteps, setGoSteps] = useState(0);
  return (
    <>
      <Header />
      <section className="altbg p-2 p-md-5">
        <div className="container-xxl">
          <div className="row h-100">
            <div className="col-md-7 col-lg-8 order-md-last">
              <div className="bg-white p-2 p-md-5 radius15 mb-3">
                <small>
                  {" "}
                  <Stepper className="p-0 mb-4" activeStep={goSteps}>
                    <Step onClick={() => setGoSteps(0)} />
                    <Step onClick={() => setGoSteps(1)} />
                    <Step onClick={() => setGoSteps(2)} />
                    <Step onClick={() => setGoSteps(3)} />
                    <Step onClick={() => setGoSteps(4)} />
                    <Step onClick={() => setGoSteps(5)} />
                  </Stepper>
                </small>
                
                <Form>
                  {" "}
     
                  <div>
                    {goSteps === 0 && (
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label className="text-bold">
                            RTO & City
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            list="datalistOptions"
                            type="text"
                            placeholder="AS-23-S-5840"
                          />
                          <datalist id="datalistOptions">
                            <option value="AS-23-S-5840" />
                            <option value="AS-23-S-5840" />
                            <option value="AS-23-S-5840" />
                            <option value="AS-23-S-5840" />
                            <option value="AS-23-S-5840" />
                          </datalist>
                        </Form.Group>
                        <Tabs
                          defaultActiveKey="Delhi"
                          id=""
                          className="mb-3"
                          fill
                        >
                          <Tab eventKey="Delhi" title="Delhi">
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1L Delhi</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1N Delhi</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1P Delhi</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1R Delhi</button> <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1T Delhi</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1Z Delhi</button> <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-14 Delhi</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-4C Delhi</button> <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-07 Delhi</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1L Delhi</button> <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1L Delhi</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          DL-1L Delhi</button>
                          </Tab>
                          <Tab eventKey="Mumbai" title="Mumbai">
                            MH
                          </Tab>
                          <Tab eventKey="Pune" title="Pune">
                            Pune
                          </Tab>
                          <Tab eventKey="Ahmedabad" title="Ahmedabad">
                            Ahmedabad
                          </Tab>
                        </Tabs>
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-4 offset-lg-8">
                            <Button
                              onClick={() => setGoSteps(1)}
                              variant="secondary radius30 w-100 mb-3"
                            >
                              Next{" "}
                              <i className="w-circle ms-2">
                                <img
                                  src="../images/icon/right-arrow.svg"
                                  alt="arrow"
                                ></img>
                              </i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {goSteps === 1 && (
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label className="text-bold">
                            Vehicle Manufacturer
                          </Form.Label>
                          <Form.Control
                            size="lg"
                            list="datalistOptions"
                            type="text"
                            placeholder="Ford"
                          />
                          <datalist id="datalistOptions">
                            <option value="Mahindra" />
                            <option value="Honda" />
                            <option value="Toyota" />
                            <option value="Audi" />
                            <option value="Mercedes" />
                          </datalist>
                        </Form.Group>
                        <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          <img src="../images/brand/tata.svg" className="d-block" alt="Tata"></img>
                          TATA</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          <img src="../images/brand/tata.svg" className="d-block" alt="Tata"></img>
                          TATA</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          <img src="../images/brand/tata.svg" className="d-block" alt="Tata"></img>
                          TATA</button>
                          <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                          <img src="../images/brand/tata.svg" className="d-block" alt="Tata"></img>
                          TATA</button>
                     

                        <div className="row">
                          <div className=" col-lg-3 offset-lg-1"></div>
                          <div className="col-sm-6 col-md-6 col-lg-4">
                            <Button
                              variant="primary w-100 radius30"
                              onClick={() => setGoSteps(0)}
                            >
                              <i className="w-circle me-2">
                                <img
                                  src="../images/icon/left-arrow.svg"
                                  alt="Arrow"
                                ></img>
                              </i>
                              Previous{" "}
                            </Button>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4">
                            <Button
                              variant="secondary radius30 w-100 mb-3"
                              onClick={() => setGoSteps(2)}
                            >
                              Next{" "}
                              <i className="w-circle ms-2">
                                <img
                                  src="../images/icon/right-arrow.svg"
                                  alt="arrow"
                                ></img>
                              </i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {goSteps === 2 && (
                    <div>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-bold">
                      Search Car model e.g. Creta

                      </Form.Label>
                      <Form.Control
                        size="lg"
                        list="datalistOptions"
                        type="text"
                        placeholder="Figo"
                      />
                      <datalist id="datalistOptions">
                        <option value="XCENT" />
                        <option value="CRETA" />
                        <option value="ELANTRA" />
                        <option value="EON" />
                        <option value="GRAND i10" />
                      </datalist>
                    </Form.Group>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    XCENT</button>
                    <div className="row">
                      <div className=" col-lg-3 offset-lg-1"></div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <Button
                          variant="primary w-100 radius30"
                          onClick={() => setGoSteps(1)}
                        >
                          <i className="w-circle me-2">
                            <img
                              src="../images/icon/left-arrow.svg"
                              alt="Arrow"
                            ></img>
                          </i>
                          Previous{" "}
                        </Button>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <Button
                          variant="secondary radius30 w-100 mb-3"
                          onClick={() => setGoSteps(3)}
                        >
                          Next{" "}
                          <i className="w-circle ms-2">
                            <img
                              src="../images/icon/right-arrow.svg"
                              alt="arrow"
                            ></img>
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                    )}
  {goSteps === 3 && (
                    <div>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-bold">
                      Search Vehicle variant
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        list="datalistOptions"
                        type="text"
                        placeholder="Base 1.2 Ti-VCT (1196 Cc) - PETROL"
                      />
                      <datalist id="datalistOptions">
                        <option value="Alpha 1.2 (1197 CC) - PETROL" />
                        <option value="Alpha 1.2 (1197 CC) - PETROL" />
                        <option value="Alpha 1.2 (1197 CC) - PETROL" />
                        <option value="Alpha 1.2 (1197 CC) - PETROL" />
                        <option value="Alpha 1.2 (1197 CC) - PETROL" />
                      </datalist>
                    </Form.Group>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    Alpha 1.2 (1197 CC) - PETROL</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    Alpha 1.2 (1197 CC) - PETROL</button><button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    Alpha 1.2 (1197 CC) - PETROL</button><button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    Alpha 1.2 (1197 CC) - PETROL</button><button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    Alpha 1.2 (1197 CC) - PETROL</button><button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    Alpha 1.2 (1197 CC) - PETROL</button><button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    Alpha 1.2 (1197 CC) - PETROL</button>
                    <div className="row">
                      <div className=" col-lg-3 offset-lg-1"></div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <Button
                          variant="primary w-100 radius30"
                          onClick={() => setGoSteps(2)}
                        >
                          <i className="w-circle me-2">
                            <img
                              src="../images/icon/left-arrow.svg"
                              alt="Arrow"
                            ></img>
                          </i>
                          Previous{" "}
                        </Button>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <Button
                          variant="secondary radius30 w-100 mb-3"
                          onClick={() => setGoSteps(4)}
                        >
                          Next{" "}
                          <i className="w-circle ms-2">
                            <img
                              src="../images/icon/right-arrow.svg"
                              alt="arrow"
                            ></img>
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                    )}

{goSteps === 4 && (
                    <div>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-bold">
                      Search Vehicle Registration year
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        list="datalistOptions"
                        type="text"
                        placeholder="2017"
                      />
                      <datalist id="datalistOptions">
                        <option value="2023" />
                        <option value="2022" />
                        <option value="2021" />
                        <option value="2020" />
                        <option value="2019" />
                      </datalist>
                    </Form.Group>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>
                    <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>  <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>  <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>  <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>  <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>  <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>  <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>  <button type="button" class="btn btn-light shadow-sm mb-3 ms-2">
                    2017</button>
                    <div className="row">
                      <div className=" col-lg-3 offset-lg-1"></div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <Button
                          variant="primary w-100 radius30"
                          onClick={() => setGoSteps(3)}
                        >
                          <i className="w-circle me-2">
                            <img
                              src="../images/icon/left-arrow.svg"
                              alt="Arrow"
                            ></img>
                          </i>
                          Previous{" "}
                        </Button>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <Button
                          variant="secondary radius30 w-100 mb-3"
                          onClick={() => setGoSteps(5)}
                        >
                          Next{" "}
                          <i className="w-circle ms-2">
                            <img
                              src="../images/icon/right-arrow.svg"
                              alt="arrow"
                            ></img>
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                    )}
 {goSteps === 5 && (
                    <div>
                    <Form.Group className="mb-4 mt-5">
                      <Form.Label className="text-bold mb-3" >
                      Previous Policy Type
                      </Form.Label>
                      <div>
                      <Form.Check inline label="Third party " name="group1" type="radio" />
                      <Form.Check inline label="Comprehensive " name="group1" type="radio" />
                      <Form.Check inline label="Standalone Own-Damage" name="group1" type="radio" />
                      </div>
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <div className="row">
                          <div className="col-md-6">
                          <Form.Label className="text-bold">
                          Previous policy insurer
                      </Form.Label>
                          <Form.Select size="lg">
        <option>Large select</option>
                </Form.Select>
                          </div>
                          <div className="col-md-6">
                          <Form.Label className="text-bold">
                          NCB on previous policy
                      </Form.Label>
                          <Form.Select size="lg">
        <option>Large select</option>
                </Form.Select>
                          </div>
                        </div>
                  
                       </Form.Group>

                      <Form.Group className="mb-5">
                      <Form.Label className="text-bold mb-3" >
                      Policy Expiring status
                      </Form.Label>
                      <div>
                      <Form.Check inline label="Expired" name="status" type="radio" />
                      <Form.Check inline label="Not Expired " name="status" type="radio" />
                      </div>
                     
                      </Form.Group>


                    <div className="row">
                      <div className=" col-lg-3 offset-lg-1"></div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <Button
                          variant="primary w-100 radius30"
                          onClick={() => setGoSteps(4)}
                        >
                          <i className="w-circle me-2">
                            <img
                              src="../images/icon/left-arrow.svg"
                              alt="Arrow"
                            ></img>
                          </i>
                          Previous{" "}
                        </Button>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                      <Link to="/vehicle-result">
                        <Button
                          variant="secondary radius30 w-100 mb-3"
                           >
                          Submit{" "}
                          <i className="w-circle ms-2">
                            <img
                              src="../images/icon/right-arrow.svg"
                              alt="arrow"
                            ></img>
                          </i>
                        </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                    )}                   

                  </div>
                </Form>{" "}
              </div>{" "}
            </div>
            <div className="col-md-5 col-lg-4 ">
       
            <div className="bg-white  p-5 radius15">
<Form>
  <Form.Group className="mb-3">
    <Form.Label>Enter Vehicle Registration Number</Form.Label>

    <InputGroup className="mb-3">
      <Form.Control
        placeholder="AS-23-S-5840"
        aria-describedby="basic-addon2"
      />
      <InputGroup.Text id="basic-addon2">
        {" "}
        <img
          src="../images/icon/edit.svg"
          alt="Arrow" className="pointer"
          onClick={() => setGoSteps(0)}
        ></img>
      </InputGroup.Text>
    </InputGroup>
    
  </Form.Group>
</Form>
<ol className="timeline">
  <li >
    <img
      src="../images/icon/edit.svg"
      alt="Arrow"
      className="float-end pointer"
      onClick={() => setGoSteps(0)}
    ></img>
    <strong className="d-block">RTO & City</strong>{" "}
    <span>AS-23 Tinsukia(assam)</span>
  </li>
  <li>
    <img
      src="../images/icon/edit.svg"
      alt="Arrow"
      className="float-end pointer"
      onClick={() => setGoSteps(1)}
    ></img>
    <strong className="d-block">Manufacturer</strong>{" "}
    <span>Ford</span>
  </li>
  <li>
    <img
      src="../images/icon/edit.svg"
      alt="Arrow"
      className="float-end pointer"
      onClick={() => setGoSteps(2)}
    ></img>
    <strong className="d-block">Model</strong> <span>Figo</span>
  </li>
  <li>
    <img
      src="../images/icon/edit.svg"
      alt="Arrow"
      className="float-end pointer"
      onClick={() => setGoSteps(3)}
    ></img>
    <strong className="d-block">Variant</strong>{" "}
    <span>Base 1.2 Ti-VCT (1196 Cc) - PETROL</span>
  </li>
  <li>
    <img
      src="../images/icon/edit.svg"
      alt="Arrow"
      className="float-end pointer"
      onClick={() => setGoSteps(4)}
    ></img>
    <strong className="d-block">Registration Year</strong>{" "}
    <span>2017</span>
  </li>
  <li>
    <img
      src="../images/icon/edit.svg"
      alt="Arrow"
      className="float-end pointer"
      onClick={() => setGoSteps(5)}
    ></img>
    <strong className="d-block">Previous Policy Status</strong>{" "}
    <span>Not Expired</span>
  </li>
</ol>
</div>            </div>
          </div>
        </div>
      </section>
      <section>
        <img src="../images/bg-wave.svg" className="w-100" alt="wave" />
      </section>
      <Footer />
    </>
  );
};
export default VehicleJourney;
