import React from "react";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-multi-date-picker"
import InputIcon from "react-multi-date-picker/components/input_icon"
const VehicleResult = () => {
 // const [ onChange] = useState(new Date());
 // const [setGoSteps] = useState(0);
  const [modalState, setModalState] = useState("close");
  //const [show, setShow] = useState(false);
  const handleShowModalOne = () => {
    setModalState("modal-one")
   }
   const handleShowModalTwo = () => {
    setModalState("modal-two")
   }
   const handleShowModalThree = () => {
    setModalState("modal-three")
   }
   const handleShowModalFour = () => {
    setModalState("modal-four")
   }

   const handleClose = () => {
    setModalState("close")
   }
  return (
    <>
      <Header />
      <section className="altbg pt-4 pb-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-md-12">
              <div className="mt-1 mb-3">
                {" "}
                <strong className="me-2">
                  Ford Figo Base 1.2 Ti-VCT (1196 Cc)
                </strong>{" "}
                Policy Expiry Date 1-Feb-2023
                <span className="ms-2" onClick={handleShowModalOne}>
                  <i className="fa fa-pencil-square-o pointer"></i>
                </span>
              </div>
              <div>
                <ul className="list-inline seprater">
                  <li className="list-inline-item">Private Car </li>
                  <li className="list-inline-item">2017</li>
                  <li className="list-inline-item">Petrol </li>
                  <li className="list-inline-item"> AS23S5840</li>
                </ul>
              </div>
            </div>
          </div>
         <Modal show={modalState === "modal-one"}>
        <Modal.Header >
        <div className="d-flex align-items-start text-bold primary-color">Ford  Figo Base 1.2 Ti-VCT (1196 Cc)</div>
<div className="d-flex align-items-end">
  <Link to="/vehicle-journey"  ><i className="fa fa-pencil-square-o pointer" aria-hidden="true"></i></Link>
  </div>
        </Modal.Header>
        <Modal.Body className="p-4">
                     <Form.Group className="mb-3" >
                      <Form.Label className="mb-2 text-bold" >
                      Vehicle Type
                      </Form.Label>
                      <div>
                      <Form.Check inline label="Private " name="group1" type="radio" />
                      <Form.Check inline label="Commercial " name="group1" type="radio" />
                      </div>
                      </Form.Group>
                      <Form.Group className="mb-3" >
                     <Form.Label className="mb-2 text-bold d-block" >
                     Registration Date 
                      </Form.Label>
                      <DatePicker style={{width: "100%", boxSizing: "border-box", }} containerStyle={{ width: "100%" }}
                     calendarPosition="bottom-center" render={<InputIcon/>} />
                      </Form.Group>   

                      <Form.Group className="mb-3" >
                     <Form.Label className="mb-2 text-bold d-block" >
                     Policy Expiry Date
                      </Form.Label>
                      <DatePicker style={{width: "100%", boxSizing: "border-box", }} containerStyle={{ width: "100%" }}
                     calendarPosition="bottom-center" render={<InputIcon/>} />
                      </Form.Group>  
                      <div className="row">
          <div className="col-md-6">    <Button variant="primary" onClick={handleClose} className="btn btn-primary radius30 w-100">
            Close
          </Button></div>
          <div className="col-md-6"> <Button variant="secondary" onClick={handleClose} className="btn btn-secondary radius30 w-100" >
          Update
          </Button>
      </div>
         </div>
        </Modal.Body>
      </Modal>
      <Modal show={modalState === "modal-two"}>
      <Modal.Header >
        <div className="d-flex align-items-start text-bold primary-color">Cover Amount (IDV)</div>
<div className="d-flex align-items-end"></div>
        </Modal.Header>
        <Modal.Body className="p-4">
                     <Form.Group className="mb-3" >
                      <Form.Label className="mb-2 text-bold" >
                      IDV
                      </Form.Label>
                      <div>
                      <Form.Check inline label="Choose My Own " name="group1" type="radio" />
                      <Form.Check inline label="Best Deal " name="group1" type="radio" />
                      </div>
                      </Form.Group>
                      <Form.Group className="mb-3" >
                     <Form.Label className="mb-2 text-bold d-block" >
                     Maximum IDV
                      </Form.Label>
                      <InputGroup className="mb-3">
        <InputGroup.Text ><i class="fa fa-inr" aria-hidden="true"></i>
</InputGroup.Text>
        <Form.Control
          placeholder=""
          aria-label=""
          
        />
           <Alert variant="light" className="p-1  rounded-3 text-center   bg-light border w-100 m-0 mt-0 fs-6">

<i>IDV should be between Rs. 1,78,000 and
Rs. 2,69,000.</i>
   
</Alert>
      </InputGroup>
   
                      </Form.Group>   

                      <Form.Group className="mb-3" >
                     <Form.Label className="mb-2 text-bold d-block" >
                     Registration Date
                      </Form.Label>
                      <DatePicker style={{width: "100%", boxSizing: "border-box", }} containerStyle={{ width: "100%" }}
                     calendarPosition="bottom-center" render={<InputIcon/>} />
                      </Form.Group>  
                      <div className="row">
          <div className="col-md-6">    <Button variant="primary" onClick={handleClose} className="btn btn-primary radius30 w-100">
            Close
          </Button></div>
          <div className="col-md-6"> <Button variant="secondary" onClick={handleClose} className="btn btn-secondary radius30 w-100" >
          Update
          </Button>
      </div>
         </div>


        </Modal.Body>
      </Modal>
      <Modal show={modalState === "modal-three"}>
      <Modal.Header >
        <div className="d-flex align-items-start text-bold primary-color">No Claim Bonus</div>
<div className="d-flex align-items-end"></div>
        </Modal.Header>
        <Modal.Body className="p-4">
                     <Form.Group className="mb-3" >
                      <Form.Label className="mb-2 text-bold" >
                      NCB on previous policy
                      </Form.Label>
                      <Form.Select>
              <option >0%</option>
              <option >20%</option>
              <option >25%</option>
              <option >35%</option>
              <option >45%</option>
              <option >50%</option>
              <option >Not sure</option>
      </Form.Select>
                      </Form.Group>
                      <div className="row">
          <div className="col-md-6">    <Button variant="primary" onClick={handleClose} className="btn btn-primary radius30 w-100">
            Close
          </Button></div>
          <div className="col-md-6"> <Button variant="secondary" onClick={handleClose} className="btn btn-secondary radius30 w-100" >
          Update
          </Button>
      </div>
         </div>
        </Modal.Body>
      </Modal>
      <Modal show={modalState === "modal-four"}>
      <Modal.Header >
        <div className="d-flex align-items-start text-bold primary-color h5 mb-0">Verify your details</div>
       <div className="d-flex align-items-end"><i className="fa fa-times  primary-color pointer" aria-hidden="true" onClick={handleClose}></i>

</div>
        </Modal.Header>
        <Modal.Body className="p-0 pb-4">
     
           
                     <div className="row">
                      <div className="col-md-12">
                        <div className="altbg p-3">
                          <div className="mb-2">
                         <strong className="h5 text-bold">Vehicle Details </strong>   <Link to="/vehicle-journey"  ><i className="fa fa-pencil-square-o pointer" aria-hidden="true"></i></Link>
                         </div>

                          <div className="row">
                            <div className="col-md-6">
                            <h3 className="h6 text-bold">Registration Date</h3>
                            <h4  className="h6 mb-3 "> 02/11/2017</h4>
                            <h3 className="h6 text-bold">Make & Model</h3>
                            <h4  className="h6 mb-3 "> Ford Figo Base TI-VCT (1196 CC)</h4>
                            </div>
                            <div className="col-md-6">
                            <h3 className="h6 text-bold">Manufacturing year</h3>
                            <h4  className="h6 mb-3 "> 2017</h4>
                            <h3 className="h6 text-bold">Fuel Type</h3>
                            <h4  className="h6 mb-3 "> Petrol</h4>
                            </div>
                          </div>
                        </div>            
                        <div className=" p-3">
                          <div className="mb-2">
                         <strong className="h5 text-bold">Previous Policy  Details </strong>   <Link to="/vehicle-journey"  ><i className="fa fa-pencil-square-o pointer" aria-hidden="true"></i></Link>
                         </div>
                          <div className="row">
                            <div className="col-md-6">
                            <h3 className="h6 text-bold">Insurer & Policy Type</h3>
                            <h4  className="h6 mb-3 "> Comprehensive</h4>
                            </div>
                            <div className="col-md-6">
                            <h3 className="h6 text-bold">Policy Expiry Date</h3>
                            <h4  className="h6 mb-3 "> 03/01/2023</h4>
                            </div>
                          </div>
                        </div>
                    </div>
                     </div>
                      <div className="row">
          <div className="col-md-6 offset-md-3 mt-2"> 
          <Link to="/kyc">
          <Button variant="secondary" className="btn btn-secondary radius30 w-100 shadow" >
          Proceed to Buy
          </Button>
          </Link>
      </div>
         </div>
        </Modal.Body>
      </Modal>


          <div className="row">
            <div className="col-md-4 col-lg-4 col-xl-4">
              <div className="bg-white  p-4 radius15 ">
                {/*Addons section start here */}
                <h4 className="text-bold h4 m-0 p-0">Addons</h4>
                <div className="dotted-line mt-2 mb-3"></div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Search..."
                    aria-label="Search..."
                    aria-describedby="basic-addon2"
                  />
                  <Button className="btn-secondary">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </Button>
                </InputGroup>
                <div class="overflow-auto height300 mb-3 ">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                      Zero Depreciation
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked
                    />
                    <label className="form-check-label">
                      24x7 Roadside Assistance
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Engine Protection Cover

                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Consumables
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Key & Lock Replacement

                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                      Zero Depreciation
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                      Zero Depreciation
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                      Zero Depreciation
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                      Zero Depreciation
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                      Zero Depreciation
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                      Zero Depreciation
                    </label>
                  </div>
                </div>
                {/*Addons section end here */}

                {/*Insurance Companies section start here */}
                <h4 className="text-bold h4 m-0 p-0">Insurance
 Companies</h4>
                <div className="dotted-line mt-2 mb-3"></div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Search..."
                    aria-label="Search..."
                    aria-describedby="basic-addon2"
                  />
                  <Button className="btn-secondary">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </Button>
                </InputGroup>
                <div class="overflow-auto height300 mb-3">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Bajaj Allianz

                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked
                    />
                    <label className="form-check-label">
                    Cholamandalam                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Future Generali 

                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Liberty  General Insurance

                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    National Insurance

                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Go Digit General Insurance 
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    SBI Life Insurance
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Shriram Life Insurance 
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Niva Bupa Health Insurance
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Reliance General Insurance Co. Ltd
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <label className="form-check-label" for="">
                    Royal Sundaram General Insurance 
                    </label>
                  </div>
                </div>
                {/*Insurance Companies section  end here */}
                <div>
                  <img
                    src="../images/about-us.webp"
                    className="img-fluid border" alt="about"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-8 col-xl-8">
              <div className="row gx-2">
                <div className="col-md-6 col-lg-6 col-xl-4">
                  <div className="bg-custom-primary text-white p-3 radius30 mb-2">
                    <span className=""></span>
                    IDV Cover{" "}
                    <span className="float-end">
                      Select IDV{" "}
                        <i className="fa fa-pencil-square-o pointer text-white" onClick={handleShowModalTwo}></i>
                    </span>
                  </div>
                </div>
                <div className="col-md-6  col-lg-6 col-xl-4">
                  <div className="bg-custom-primary text-white p-3 radius30 mb-2">
                    No Claim Bonus
                    <span className="float-end">
                      25%{" "}
                  
                        <i className="fa fa-pencil-square-o pointer text-white" onClick={handleShowModalThree}></i>
                     
                    </span>
                  </div>
                </div>
                <div className="col-md-6  col-lg-6 col-xl-4">
                  <div className="bg-custom-primary text-white p-3  border-0 radius30 mb-2">
                    <select className="bg-custom-primary bdrnone  text-white  w-100">
                      <option selected className="border-0">
                        Select plan type
                      </option>
                      <option value="Select plan type">Third party </option>
                      <option value="Select plan type">Comprehensive </option>
                      <option value="Select plan type">Standalone Own-Damage </option>

                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-4">
                <div className="row">
                  <div className="col-md-12">
                    {/* heading ---------------------*/}
                    <div className="mainheading-bdr p-2  radius30">
                      <div className="row">
                        <div className="col-lg-8 col-md-6">
                          <h3 className="h4 text-bold pt-2 px-3 primary-color">
                            3 Third party Plans Available
                          </h3>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div class="row gx-1">
                            <label
                              for="colFormLabel"
                              className="col-sm-4 col-form-label text-center text-lg-end "
                            >
                              Sort by{" "}
                            </label>
                            <div className="col-sm-8">
                              <div className="bg-custom-secondary p-2  radius30 text-white">
                                <select className="bg-custom-secondary bdrnone  border-0   text-white  text-center w-100">
                                  <option selected className="border-0">
                                    Low to high
                                  </option>
                                  <option value="Select plan type">
                                    Low to high
                                  </option>
                                  <option value="Select plan type">
                                    Low to high
                                  </option>
                                  <option value="Select plan type">
                                    Low to high
                                  </option>
                                </select>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* heading end---------------------*/}

                    {/* List start ---------------------*/}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="bg-white shadow-sm  p-3 radius15 mt-4 mb-2">
                        <div className="row">
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <img src="../images/clients/bajaj.webp" className="img-fluid" alt="bajaj"></img>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <h2 className="h5 text-bold primary-color">IDV Cover : ₹ 1,45,125</h2>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                      <Button variant="secondary radius30 w-75 " onClick={handleShowModalFour}>
                      ₹ 5,818<i className="w-circle ms-2"><img src="../images/icon/right-arrow.svg" alt="ok"></img></i>
                  </Button>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-8">
                      <ul className="list-unstyled text-center text-lg-start">
                        <li className="mb-2 mt-2  text-left d-block">
                           <Alert variant="success"className="p-2   badge bg-custom-primary  border-0 m-0 text-white">
                        <strong>Addon:  </strong>  24x7 Roadside Assistance    <span  className="ms-3 pointer"  variant="outline-success">
          <i className="fa fa-times" aria-hidden="true"></i>
          </span>
      </Alert></li>

     {/*<li className="mb-2  text-left d-block">     <Alert show={show} variant="success"className="p-2   badge bg-custom-primary  border-0 m-0 text-white">
                       Zero Depreciation  <span  className="ms-3 pointer" onClick={() => setShow(false)} variant="outline-success">
          <i className="fa fa-times" aria-hidden="true"></i>
          </span>
      </Alert></li> */} 


                      </ul>
     

                     </div>
                    </div>
                        </div>
                      </div>
                    </div>
                    {/* List End ---------------------*/}
                    {/* List start ---------------------*/}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="bg-white shadow-sm  p-3 radius15 mt-4 mb-2">
                        <div className="row">
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <img src="../images/clients/bajaj.webp" className="img-fluid" alt="bajaj"></img>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <h2 className="h5 text-bold primary-color">IDV Cover : ₹ 1,45,125</h2>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                      <Button variant="secondary radius30 w-75">
                      ₹ 5,818<i className="w-circle ms-2"><img src="../images/icon/right-arrow.svg" alt="ok"></img></i>
                  </Button>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                    <div className="text-center text-lg-start alert alert-warning bg-transparent border-0  pl-3 p-0  " role="alert">
  <div>
  <i className="fa fa-exclamation-triangle me-2" aria-hidden="true"></i>
  Inspection required
  </div>
</div>
                     </div>
                    </div>
                        </div>
                      </div>
                    </div>
                    {/* List End ---------------------*/}
                                    {/* List start ---------------------*/}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="bg-white shadow-sm  p-3 radius15 mt-4 mb-2">
                        <div className="row">
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <img src="../images/clients/bajaj.webp" className="img-fluid" alt="bajaj"></img>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <h2 className="h5 text-bold primary-color">IDV Cover : ₹ 1,45,125</h2>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                      <Button variant="secondary radius30 w-75">
                      ₹ 5,818<i className="w-circle ms-2"><img src="../images/icon/right-arrow.svg" alt="ok"></img></i>
                  </Button>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                    <div className="alert alert-warning bg-transparent border-0  pl-3 p-0  d-flex align-items-center" role="alert">
  <div>
  <i className="fa fa-exclamation-triangle me-2" aria-hidden="true"></i>
  Inspection required
  </div>
</div>
                     </div>
                    </div>
                        </div>
                      </div>
                    </div>
                    {/* List End ---------------------*/}


                            {/* heading ---------------------*/}
                            <div className="mainheading-bdr  radius30 mt-4 p-2">
                      <div className="row">
                        <div className="col-lg-8 col-md-6">
                          <h3 className="h4 text-bold pt-2 px-3 primary-color">
                            2 Comprehensive Plans Available
                          </h3>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div class="row gx-1">
                            <label
                              for="colFormLabel"
                              className="col-sm-4 col-form-label text-center text-lg-end "
                            >
                              Sort by{" "}
                            </label>
                            <div className="col-sm-8">
                              <div className="bg-custom-secondary p-2  radius30 text-white">
                                <select className="bg-custom-secondary bdrnone  border-0   text-white  text-center w-100">
                                  <option selected className="border-0">
                                    Low to high
                                  </option>
                                  <option value="Select plan type">
                                    Low to high
                                  </option>
                                  <option value="Select plan type">
                                    Low to high
                                  </option>
                                  <option value="Select plan type">
                                    Low to high
                                  </option>
                                </select>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* heading end---------------------*/}
                     
                                    {/* List start ---------------------*/}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="bg-white shadow-sm  p-3 radius15 mt-4 mb-2">
                        <div className="row">
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <img src="../images/clients/bajaj.webp" className="img-fluid" alt="bajaj"></img>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <h2 className="h5 text-bold primary-color">IDV Cover : ₹ 1,45,125</h2>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                      <Button variant="secondary radius30 w-75">
                      ₹ 5,818<i className="w-circle ms-2"><img src="../images/icon/right-arrow.svg" alt="ok"></img></i>
                  </Button>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                    <div className="alert alert-warning bg-transparent border-0  pl-3 p-0  d-flex align-items-center" role="alert">
  <div>
  <i className="fa fa-exclamation-triangle me-2" aria-hidden="true"></i>
  Inspection required
  </div>
</div>
                     </div>
                    </div>
                        </div>
                      </div>
                    </div>
                    {/* List End ---------------------*/}
                     
                                    {/* List start ---------------------*/}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="bg-white shadow-sm  p-3 radius15 mt-4 mb-2">
                        <div className="row">
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <img src="../images/clients/bajaj.webp" className="img-fluid" alt="bajaj"></img>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <h2 className="h5 text-bold primary-color">IDV Cover : ₹ 1,45,125</h2>
                      </div>
                      <div className="col-md-4 d-flex align-items-center justify-content-center">
                      <Button variant="secondary radius30 w-75">
                      ₹ 5,818<i className="w-circle ms-2"><img src="../images/icon/right-arrow.svg" alt="ok"></img></i>
                  </Button>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                    <div className="alert alert-warning bg-transparent border-0  pl-3 p-0  d-flex align-items-center" role="alert">
  <div>
  <i className="fa fa-exclamation-triangle me-2" aria-hidden="true"></i>
  Inspection required
  </div>
</div>
                     </div>
                    </div>
                        </div>
                      </div>
                    </div>
                    {/* List End ---------------------*/}
                     

                  </div>
                </div>
                <img src="../images/cta.webp" className="img-fluid" alt="CTA"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <img src="../images/bg-wave.svg" className="w-100" alt="wave" />
      </section>
      <Footer />
    </>
  );
};
export default VehicleResult;
