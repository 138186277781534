import React from "react";
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const Header=()=>{
    return (
      <>

          <div className="container-xxl">     
          <Navbar bg="white" expand="lg" id="navbar_top">

<Navbar.Brand ><Link  to="/">  <img src='../images/logo.svg' alt="logo" /></Link></Navbar.Brand>
<Navbar.Toggle aria-controls="navbarScroll" />
<Navbar.Collapse id="navbarScroll">
<Nav className="ms-auto navbar-nav">
<Nav.Link>   
<Link className="nav-item primary-color" to="/"> Home</Link>
</Nav.Link>

<NavDropdown title="About us" className="dropdown-menu animate slideIn">
  <NavDropdown.Item>
<Link className="nav-item" to="/"> Home</Link>
</NavDropdown.Item>
  <NavDropdown.Item >
<Link className="nav-item" to="/"> Home</Link>
  </NavDropdown.Item>
ss
</NavDropdown>   <NavDropdown title=" About us">
  <NavDropdown.Item>
<Link className="nav-item" to="/"> Home</Link>
</NavDropdown.Item>
  <NavDropdown.Item >
<Link className="nav-item" to="/"> Home</Link>
  </NavDropdown.Item>

</NavDropdown>   <NavDropdown title="Products">
  <NavDropdown.Item>
<Link className="nav-item" to="/vehicle-insurance">Car  Insurance </Link>
</NavDropdown.Item>
  <NavDropdown.Item >
<Link className="nav-item" to="/"> 2 Wheeler Insurance</Link>
  </NavDropdown.Item>

</NavDropdown>
<NavDropdown title="Make  a  claim">
  <NavDropdown.Item>
<Link className="nav-item" to="/"> Home</Link>
</NavDropdown.Item>
  <NavDropdown.Item >
<Link className="nav-item" to="/"> Home</Link>
  </NavDropdown.Item>
</NavDropdown>
<NavDropdown title="POS corner">
  <NavDropdown.Item>
<Link className="nav-item" to="/"> Home</Link>
</NavDropdown.Item>
  <NavDropdown.Item >
<Link className="nav-item" to="/"> Home</Link>
  </NavDropdown.Item>
</NavDropdown>
<NavDropdown title="Support">
  <NavDropdown.Item>
<Link className="nav-item" to="/"> Home</Link>
</NavDropdown.Item>
  <NavDropdown.Item >
<Link className="nav-item" to="/"> Home</Link>
  </NavDropdown.Item>
</NavDropdown>
</Nav>
<Nav className="gap-2">
<Link  to="/" className="btn btn-outline-secondary semibold">
  Sign up
</Link>
</Nav>
</Navbar.Collapse>

</Navbar></div>

   
        </>
      );
    }
    
export default Header;

