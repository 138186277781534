import React from "react";
import OwlCarousel from "react-owl-carousel";
//Owl Carousel Settings
const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  navText: [
    "<div class='nav-button text-center owl-prev'><img src='./images/icon/prev.svg' alt='prev'/></div>",
    "<div class='nav-button owl-next'><img src='./images/icon/next.svg' alt='next'/></div>",
  ],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};
const Testimonial = () => {
  return (
    <section className="section">
      <h3 className="text-center mainheading mb-5">
        Meet Client <span className="secondary-color">Satisfaction </span>
      </h3>
      <div className="container-xxl">
        <div className="row">
          <div className="col-md-12">
            <div className="testimonial">
              <OwlCarousel className="slider-items owl-carousel " {...options}>
                <div className="item">
                  <div className="p-4 m-3 bg-white shadow">
                    <p>
                      “Thanking you very much for your support for getting our
                      policy quickly, I would appreciate your work. Thanking you
                      very much for your support for getting our policy quickly,
                      I would appreciate your work”{" "}
                    </p>
                    <div className="d-flex">
                      <img src="./images/preeti.png"
                        className="testi-img"
                      alt="preeti" ></img>
                      <div>
                        <h5 className="mb-0">Preeti sharma</h5>
                        <div className="mt-0 secondary-color">Gurgaon</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="p-4 m-3 bg-white shadow">
                    <p>
                      “Thanking you very much for your support for getting our
                      policy quickly, I would appreciate your work. Thanking you
                      very much for your support for getting our policy quickly,
                      I would appreciate your work”{" "}
                    </p>
                    <div className="d-flex">
                      <img src="./images/preeti.png"
                        className="testi-img"
                        alt="preeti" ></img>
                      <div>
                        <h5 className="mb-0">Preeti sharma</h5>
                        <div className="mt-0 secondary-color">Gurgaon</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="p-4 m-3 bg-white shadow">
                    <p>
                      “Thanking you very much for your support for getting our
                      policy quickly, I would appreciate your work. Thanking you
                      very much for your support for getting our policy quickly,
                      I would appreciate your work”{" "}
                    </p>
                    <div className="d-flex">
                      <img src="./images/preeti.png"
                        className="testi-img"
                        alt="preeti"></img>
                      <div>
                        <h5 className="mb-0">Preeti sharma</h5>
                        <div className="mt-0 secondary-color">Gurgaon</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="p-4 m-3 bg-white shadow">
                    <p>
                      “Thanking you very much for your support for getting our
                      policy quickly, I would appreciate your work. Thanking you
                      very much for your support for getting our policy quickly,
                      I would appreciate your work”{" "}
                    </p>
                    <div className="d-flex">
                      <img src="./images/preeti.png"
                        className="testi-img"
                        alt="preeti"  ></img>
                      <div>
                        <h5 className="mb-0">Preeti sharma</h5>
                        <div className="mt-0 secondary-color">Gurgaon</div>
                      </div>
                    </div>
                  </div>
                </div>


              </OwlCarousel>
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    </section>
  );
};
export default Testimonial;
