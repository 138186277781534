import React from "react";
const KycProcess = () => {
  return (
    <>
      <div className="kyc-wrapper pt-4 ">
        <h2 className="h2 text-center text-bold-800 primary-color">
          KYC Process
        </h2>
        <p className="text-center">
          It is imperative to register for KYC before purchasing an Insurance
          Policy
        </p>
        <h3 className="h6 text-center text-bold-800 primary-color">
          What can we do for your KYC process?
        </h3>
        <div className="text-center">
          <img
            src="../images/cta-girl.svg"
            className="img-fluid w-50 text-center"
            alt="CTA"
          ></img>
        </div>
        <div className="row g-2 ">
          <div className=" col-lg-4 col-md-6">
            <div className="bg-white p-3 pt-0 radius15 shadow mt-2  h-100">
              <div className="text-center position-relative">
                {" "}
                <img
                  src="../images/pan-icon.webp"
                  className="img-fluid w-50"
                  alt="pan"
                ></img>
              </div>
              <h4 className="h6 text-center text-bold-800 primary-color">
                KYC With PAN Card
              </h4>
              <ul className="ms-1  list h6">
                <li className="mb-2"> Enter PAN number</li>
                <li className="mb-2"> Enter Date of Birth </li>
                <li className="mb-2"> The Policy will be issued </li>
              </ul>
            </div>
          </div>
          <div className=" col-lg-4 col-md-6">
            <div className="bg-white p-3 pt-0 radius15 shadow mt-2  h-100">
              <div className="text-center position-relative">
                {" "}
                <img
                  src="../images/aadhar-icon.webp"
                  className="img-fluid w-50"
                  alt="pan"
                ></img>
              </div>
              <h4 className="h6 text-center text-bold-800 primary-color">
                KYC with Aadhar Card
              </h4>
              <ul className="ms-1  list h6">
                <li className="mb-2"> Enter Aadhar Card Number </li>
                <li className="mb-2"> Enter the OTP received on the Mobile </li>
                <li className="mb-2"> The Policy will be issued </li>
              </ul>
            </div>
          </div>
          <div className=" col-lg-4 col-md-6">
            <div className="bg-white p-3 pt-0 radius15 shadow mt-2 h-100">
              <div className="text-center position-relative">
                {" "}
                <img
                  src="../images/upload-icon.webp"
                  className="img-fluid w-50"
                  alt="pan"
                ></img>
              </div>
              <h4 className="h6 text-center text-bold-800 primary-color">
                KYC with DL/Passport
              </h4>
              <h6 className="primary-color h6">
                {" "}
                (In case there is no PAN or no OTP for Aadhar)
              </h6>
              <ul className="ms-1  list h6">
                <li className="mb-2">Upload Aadhar card/DL/Passpost </li>
                <li className="mb-2"> Upload Customer Photo with DoB </li>
                <li className="mb-2"> The Policy will be issued </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="px-3">
            <h3 className="text-bold h2 text-center primary-color pt-3">Premium Payment without KYC</h3>
            <h4 className="h4 text-center primary-color">Policy gets stuck in the approval stage</h4>
            <p className="primary-color text-center">If the borrower pays for the policy without uploading KYC, then
 the policy will not be issued. It will be stuck in the approval stage. </p>
          </div></div>
          <div className="bg-custom-primary text-white radius15b secondarybdr p-2">
            <h3 className="text-center h4 text-bold">What to do if the Policy gets stuck in the Approval stage?
</h3>
<h6 className="text-white text-center">You can search in the transaction section of the IRDAI. You will find the KYC link.</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycProcess;
