import React from "react";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
//Owl Carousel Settings
const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  navText: ["<div class='nav-button text-center owl-prev'><img src='./images/icon/prev.svg'/></div>", "<div class='nav-button owl-next'><img src='./images/icon/next.svg'/></div>"],
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 2,
      },
      400: {
          items: 3,
      },
      600: {
          items: 4,
      },
      700: {
          items: 5,
      },
      1000: {
          items: 6,
      }
  },
};
const Clients=()=>{
  
return (
  <div className="pt-5 pb-5">  
  
  <OwlCarousel className="slider-items owl-carousel" {...options}>
                      <div class="item"><img src="./images/clients/lic.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/hdfc-life.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/max-life.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/icici.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/kotak.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/future.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/sbi-life.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/bajaj.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/shirram.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/digit.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/hdfc-argo.webp" alt="relinance"/></div>
                      <div class="item"><img src="./images/clients/reliance.webp" alt="relinance"/></div>
                  </OwlCarousel>

</div> 
      );
    }
    export default Clients;