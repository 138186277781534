import React from "react";
import { Link } from "react-router-dom";

const Homepage=()=>{
return (
  <>
    <div className="container mt-5">
      <div className="row">
        <div className="col-sm-12 text-center">
        <img src='../images/alert.svg' alt="logo" />
          <h3 className='mt-3 content'>Website is under construction</h3>
             <div className='mt-4'><img src='../images/logo.svg' alt="logo" /></div>
             <Link className="nav-item primary-color" to="vehicle-insurance"> car-insurance</Link>
        </div>
      </div>
    </div> </>

      );
    }
    export default Homepage;