import React from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import KycProcess from "./KycProcess";
const Kyc = () => {
  const [goSteps, setGoSteps] = useState(0);
  //const [otp] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, );

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
  };
  const [status, setStatus] = React.useState(1);
  const radioHandler = (status) => {
    setStatus(status);
  };

  return (
    <>
      <Header />

      <section className="altbg pt-5 pb-5 ">
        <div className="container-xxl">
          <div className="row ">
            <div className="col-lg-5 col-md-5">
              <div className="bg-white p-4 radius15t">
                <h1 className="h2 text-center text-bold-800 primary-color">
                  Know Your Customer
                </h1>
                <h2 className="h6 text-center text-bold primary-color mb-3">
                  To complete the purchase, we need the following customer
                  details.
                </h2>
                <Form>
                  {" "}
                  {goSteps === 0 && (
                    <aside>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex bd-highlight shadow-sm mb-4 ">
                            <div className=" p-2 bd-highlight">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="release"
                                checked={status === 1}
                                onClick={(e) => radioHandler(1)}
                              />
                            </div>
                            <div className="p-2 bd-highlight">
                              {" "}
                              <strong>PAN Based KYC</strong>{" "}
                              <span className="d-block h6">
                                Complete your KYC by simply providing the
                                insured's PAN and Date of Birth details.
                              </span>
                            </div>
                          </div>

                          <div className="d-flex bd-highlight shadow-sm mb-4  ">
                            <div className=" p-2 bd-highlight">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="release"
                                checked={status === 2}
                                onClick={(e) => radioHandler(2)}
                              />
                            </div>
                            <div className="p-2 bd-highlight">
                              {" "}
                              <strong>Aadhar and OTP based KYC</strong>{" "}
                              <span className="d-block h6">
                                Upload your Aadhar card on Digilocker and verify
                                with an OTP received on your registered mobile
                                no.
                              </span>
                            </div>
                          </div>
                          <div className="d-flex bd-highlight shadow-sm mb-4 ">
                            <div className="p-2 bd-highlight">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="release"
                                checked={status === 3}
                                onClick={(e) => radioHandler(3)}
                              />
                            </div>
                            <div className="p-2 bd-highlight">
                              {" "}
                              <strong>Upload Document</strong>{" "}
                              <span className="d-block h6">
                                Upload images of any of the following verified
                                documents to complete KYC.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* button code  start here-----------------*/}
                      <div className="row">
                        <div className="col-lg-6">
                          <Link to="/vehicle-result">
                            <Button variant="primary w-100 radius30">
                              <i className="w-circle me-2">
                                <img
                                  src="../images/icon/left-arrow.svg"
                                  alt="Arrow"
                                ></img>
                              </i>
                              Back to Quotes{" "}
                            </Button>
                          </Link>
                        </div>
                        <div className="col-lg-6">
                          {status === 1 && (
                            <aside>
                              {" "}
                              <Button
                                onClick={() => setGoSteps(1)}
                                variant="secondary radius30 w-100 mb-3"
                              >
                                Continue{" "}
                                <i className="w-circle ms-2">
                                  <img
                                    src="../images/icon/right-arrow.svg"
                                    alt="arrow"
                                  ></img>
                                </i>
                              </Button>
                            </aside>
                          )}
                          {status === 2 && (
                            <aside>
                              {" "}
                              <Button
                                onClick={() => setGoSteps(3)}
                                variant="secondary radius30 w-100 mb-3"
                              >
                                Continue{" "}
                                <i className="w-circle ms-2">
                                  <img
                                    src="../images/icon/right-arrow.svg"
                                    alt="arrow"
                                  ></img>
                                </i>
                              </Button>
                            </aside>
                          )}
                          {status === 3 && (
                            <aside>
                              {" "}
                              <Button
                                onClick={() => setGoSteps(4)}
                                variant="secondary radius30 w-100 mb-3"
                              >
                                Continue{" "}
                                <i className="w-circle ms-2">
                                  <img
                                    src="../images/icon/right-arrow.svg"
                                    alt="arrow"
                                  ></img>
                                </i>
                              </Button>
                            </aside>
                          )}
                        </div>
                      </div>
                      {/* button code  start here-----------------*/}
                    </aside>
                  )}
                  {goSteps === 1 && (
                    <aside>
                      <Form.Group className="mb-4 mt-4">
                        <Form.Label className="text-bold mb-3">
                          Vehicle Belongs to
                        </Form.Label>
                        <div>
                          <Form.Check
                            inline
                            label="Individual"
                            name="group1"
                            type="radio"
                          />
                          <Form.Check
                            inline
                            label="Company "
                            name="group1"
                            type="radio"
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-4 mt-4">
                        <Form.Label className="text-bold mb-3">
                          PAN Number
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="Enter PAN Number"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="mb-2 text-bold d-block">
                          Date of Birth (as per PAN card)
                        </Form.Label>
                        <DatePicker
                          size="lg"
                          style={{ width: "100%", boxSizing: "border-box" }}
                          containerStyle={{ width: "100%" }}
                          calendarPosition="bottom-center"
                          render={<InputIcon />}
                        />
                      </Form.Group>

                      {/* button code  start here-----------------*/}
                      <div className="row">
                        <div className="col-lg-6">
                          <Button
                            onClick={() => setGoSteps(0)}
                            variant="primary w-100 radius30"
                          >
                            <i className="w-circle me-2">
                              <img
                                src="../images/icon/left-arrow.svg"
                                alt="Arrow"
                              ></img>
                            </i>
                            Back{" "}
                          </Button>
                        </div>
                        <div className="col-lg-6">
                          <Button
                            onClick={() => setGoSteps(2)}
                            variant="secondary radius30 w-100 mb-3"
                          >
                            Continue{" "}
                            <i className="w-circle ms-2">
                              <img
                                src="../images/icon/right-arrow.svg"
                                alt="arrow"
                              ></img>
                            </i>
                          </Button>
                        </div>
                      </div>
                      {/* button code  start here-----------------*/}
                    </aside>
                  )}
                  {goSteps === 2 && (
                    <aside>
                      <Form.Group className="mb-4 mt-4">
                        <Form.Label className="text-bold mb-3">
                          OTP Verification{" "}
                        </Form.Label>
                        <h6 className="h6">
                          Enter the OTP sent to - +91-XXXXXX0001
                        </h6>
                        <Row className="g-4">
                          <Col md>
                            <Form.Control
                              size="lg"
                              type="text"
                              placeholder="0"
                            />
                          </Col>
                          <Col md>
                            <Form.Control
                              size="lg"
                              type="text"
                              placeholder="0"
                            />
                          </Col>
                          <Col md>
                            <Form.Control
                              size="lg"
                              type="text"
                              placeholder="0"
                            />
                          </Col>
                          <Col md>
                            <Form.Control
                              size="lg"
                              type="text"
                              placeholder="0"
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <div className="countdown-text">
                        {seconds > 0 || minutes > 0 ? (
                          <p>
                            Time Remaining:{" "}
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          <span>Didn't recieve code?</span>
                        )}

                        <button
                          className="pt-2 pb-2 px-4 bg-white  radius30 btn-light ms-2 border"
                          disabled={seconds > 0 || minutes > 0}
                          style={{
                            color:
                              seconds > 0 || minutes > 0
                                ? "#003060"
                                : "#EF8800",
                          }}
                          onClick={resendOTP}
                        >
                          Resend OTP
                        </button>
                      </div>
                      {/* button code  start here-----------------*/}
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <Button
                            onClick={() => setGoSteps(1)}
                            variant="primary w-100 radius30"
                          >
                            <i className="w-circle me-2">
                              <img
                                src="../images/icon/left-arrow.svg"
                                alt="Arrow"
                              ></img>
                            </i>
                            Back{" "}
                          </Button>
                        </div>
                        <div className="col-lg-6">
                          <Link to="/kyc-success">
                            <Button variant="secondary radius30 w-100 mb-3">
                              Submit{" "}
                              <i className="w-circle ms-2">
                                <img
                                  src="../images/icon/right-arrow.svg"
                                  alt="arrow"
                                ></img>
                              </i>
                            </Button>
                          </Link>
                        </div>
                      </div>
                      {/* button code  start here-----------------*/}
                    </aside>
                  )}
                  {goSteps === 3 && (
                    <aside>
                      <Form.Group className="mb-4 mt-4">
                        <Form.Label className="text-bold mb-3">
                          Aadhar Number
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="Enter  Aadhar  Number"
                        />
                      </Form.Group>
                      {/* button code  start here-----------------*/}
                      <div className="row">
                        <div className="col-lg-6">
                          <Button
                            onClick={() => setGoSteps(0)}
                            variant="primary w-100 radius30"
                          >
                            <i className="w-circle me-2">
                              <img
                                src="../images/icon/left-arrow.svg"
                                alt="Arrow"
                              ></img>
                            </i>
                            Back{" "}
                          </Button>
                        </div>
                        <div className="col-lg-6">
                          <Button
                            onClick={() => setGoSteps(2)}
                            variant="secondary radius30 w-100 mb-3"
                          >
                            Continue{" "}
                            <i className="w-circle ms-2">
                              <img
                                src="../images/icon/right-arrow.svg"
                                alt="arrow"
                              ></img>
                            </i>
                          </Button>
                        </div>
                      </div>
                      {/* button code  start here-----------------*/}
                    </aside>
                  )}
                  {goSteps === 4 && (
                    <aside>
                      <Form.Group className="mb-4 mt-4">
                        <Form.Label className="text-bold mb-3">
                          Select Document
                        </Form.Label>
                        <Form.Select size="lg">
                          <option>Driving Licence</option>
                          <option>Aadhaar Card</option>
                          <option>Voter ID Card</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-4 mt-4">
                        <Form.Label className="text-bold mb-3">
                          Upload front side
                        </Form.Label>
                        <Form.Control size="lg" type="file" multiple />
                      </Form.Group>
                      <Form.Group className="mb-4 mt-4">
                        <Form.Label className="text-bold mb-3">
                          Upload Back Side Document
                        </Form.Label>
                        <Form.Control size="lg" type="file" multiple />
                      </Form.Group>

                      <div className="d-flex mb-2  ">
                        <div className="mx-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="release"
                          />
                        </div>
                        <div className="p-1 bd-highlight">
                          {" "}
                          <span className="d-block h6">
                            I hereby provide consent to use my data
                          </span>
                        </div>
                      </div>

                      <div className="d-flex mb-2  ">
                        <div className="mx-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="release"
                          />
                        </div>
                        <div className="p-1 bd-highlight">
                          {" "}
                          <span className="d-block h6 ">
                            I hereby confirm that I have verified the AML/KYC
                            documents provided by the customer and the same
                            documents are uploaded in the system. Company may
                            issue the policy, even if OCR verification fails
                            basis on this declaration by me.
                          </span>
                        </div>
                      </div>

                      {/* button code  start here-----------------*/}
                      <div className="row">
                        <div className="col-lg-6">
                          <Button
                            onClick={() => setGoSteps(0)}
                            variant="primary w-100 radius30"
                          >
                            <i className="w-circle me-2">
                              <img
                                src="../images/icon/left-arrow.svg"
                                alt="Arrow"
                              ></img>
                            </i>
                            Back{" "}
                          </Button>
                        </div>
                        <div className="col-lg-6">
                          <Link to="/kyc-success">
                            <Button
                              onClick={() => setGoSteps(3)}
                              variant="secondary radius30 w-100 mb-3"
                            >
                              Continue{" "}
                              <i className="w-circle ms-2">
                                <img
                                  src="../images/icon/right-arrow.svg"
                                  alt="arrow"
                                ></img>
                              </i>
                            </Button>
                          </Link>
                        </div>
                      </div>
                      {/* button code  start here-----------------*/}
                    </aside>
                  )}
                  {goSteps === 5 && <div>6</div>}
                </Form>
              </div>

              <Alert variant="success" className="text-center mb-4 h6">
                As per Insurance Regulatory and Development Authority of India
                (IRDAI)
                <strong className="d-block mt-2 h6 text-bold">
                  {" "}
                  Effective Jan 1, 2023, KYC is mandatory for all Life, Motor &
                  Health Insurance policies.
                </strong>
              </Alert>
            </div>
            <div className="col-lg-7 col-md-7 ">
              <KycProcess />
            </div>
          </div>
        </div>
      </section>
      <section>
        <img src="../images/bg-wave.svg" className="w-100" alt="wave" />
      </section>
      <Footer />
    </>
  );
};
export default Kyc;
